.form-status {
    font-size: 20px;
}

.form-success {
    color: green;
}

.form-error {
    color: red;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3NyYy9pbmRleC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEiLCJmaWxlIjoiaW5kZXguY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmZvcm0tc3RhdHVzIHtcbiAgICBmb250LXNpemU6IDIwcHg7XG59XG5cbi5mb3JtLXN1Y2Nlc3Mge1xuICAgIGNvbG9yOiBncmVlbjtcbn1cblxuLmZvcm0tZXJyb3Ige1xuICAgIGNvbG9yOiByZWQ7XG59XG4iXX0= */