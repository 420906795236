.react-page-editable {
  .react-page-cell-handle {
    display: none;
  }
  &&-mode-edit,
  &&-mode-resizing,
  &&-mode-layout {
    .react-page-cell-handle {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
      transition: opacity ease 0.4s;
      opacity: 0;
      text-align: center;
      color: white;

      display: inline-block;
      padding: 4px 8px;
      margin: 0 auto;
      border-radius: 4px 4px 0 0;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.15em;
      background-color: #1976d2;
      pointer-events: none;
    }

    .react-page-cell-handle-drag-enabled {
      cursor: move;
    }

    .react-page-cell:hover > .react-page-cell-handle,
    .react-page-cell.react-page-cell-focused > .react-page-cell-handle {
      opacity: 1;
      pointer-events: all;
    }
  }
}
