.form-status {
    font-size: 20px;
}

.form-success {
    color: green;
}

.form-error {
    color: red;
}
